import cn from 'classnames';
import { createEvent } from 'effector';
import { ReactNode, useState, useEffect, useCallback } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import styles from './index.module.pcss';

enum Variants {
	shift = 'shift',
	overlay = 'overlay',
}

type Classes = {
	hideButton?: string;
	border?: string;
	root?: string;
	rightPart?: string;
	rightPartOpen?: string;
	leftPart?: string;
};

interface OverlayProps {
	classes?: Classes;
	open: boolean;
	hideButton?: boolean;
	rightPart: ReactNode;
	onClose?: () => void;
	onOpen?: () => void;
	size?: 'M' | 'S';
	dataTest?: string;
}

interface ShiftProps extends OverlayProps {
	leftPart: ReactNode;
}

interface CurtainProps extends OverlayProps {
	variant: keyof typeof Variants;
}

function Curtain({
	classes,
	open = false,
	hideButton = false,
	rightPart,
	onOpen,
	onClose,
	variant,
	size = 'M',
	dataTest = 'curtain-panel',
}: CurtainProps) {
	function onCloseHandler() {
		if (!open && onOpen) {
			onOpen();
		} else if (onClose) {
			onClose();
		}
	}

	return (
		<div
			className={cn(
				open ? cn(styles.opened, classes?.rightPartOpen) : styles.closed,
				styles.rightPart,
				classes?.rightPart,
				styles[size],
				{
					[styles.overlay]: variant === 'overlay',
					[styles.shift]: variant === 'shift',
				}
			)}
			data-test={dataTest}
		>
			<div className={cn(styles.border, classes?.border)}>
				{!hideButton && (
					<>
						<div className={cn(styles.backing, styles.hideButton, classes?.hideButton)} />

						<ButtonIcon
							icon="ChevronRight/Regular"
							onClick={onCloseHandler}
							className={cn(
								styles.hideButton,
								classes?.hideButton,
								open ? styles.iconClose : styles.iconOpen
							)}
							dataTest="curtain-hide-button"
							size={size === 'S' ? 'XS' : 'S'}
						/>
					</>
				)}
			</div>

			<div className={styles.background}>
				<div
					className={cn(styles.rightPartContent, open ? styles.showContent : styles.hideContent)}
				>
					{rightPart}
				</div>
			</div>
		</div>
	);
}

function CurtainShift(props: ShiftProps) {
	const { classes, leftPart } = props;

	return (
		<div className={cn(styles.container, classes?.root)}>
			<div className={cn(styles.leftPart, classes?.leftPart)}>{leftPart}</div>

			<Curtain variant="shift" {...props} />
		</div>
	);
}

function CurtainOverlay(props: OverlayProps) {
	return <Curtain variant="overlay" {...props} />;
}

//
// Common curtain for everybody! Handles opening - and closing (if called second time with the same id).
//
//
// Simple usage:
//
//		enqueueCurtain('someId', <div>Your curtain content</div>)
//
//
// Advanced usage, with passing OverlayProps to curtain:
//
//		enqueueCurtain(
//			'someId',
//			{
//				rightPart: <div>Your curtain content</div>,
//				classes: { rightPartOpened: styles.yourOptionalStyles, etc... },
//				size: 'S',
//				hideButton: true,
//				etc...
//			}
//		)
//

type CurtainId = string | number;
type ManagedOverlayProps = Omit<OverlayProps, 'variant' | 'open' | 'onOpen' | 'onClose'>;

const _curtainEvent = createEvent<{ id: CurtainId } & ManagedOverlayProps>();

function enqueueCurtain(
	id: CurtainId,
	curtainProps: ManagedOverlayProps | ManagedOverlayProps['rightPart']
) {
	const preparedCurtainProps =
		typeof curtainProps === 'object' && curtainProps !== null && 'rightPart' in curtainProps
			? curtainProps
			: { rightPart: curtainProps };

	_curtainEvent({ id, ...preparedCurtainProps });
}

function CurtainInLayout() {
	const [curtainId, setCurtainId] = useState<CurtainId | null>(null);
	const [curtainProps, setCurtainProps] = useState<ManagedOverlayProps>({ rightPart: <></> });

	const onClose = useCallback(function () {
		setCurtainId(null);
	}, []);

	useEffect(() => {
		const unwatch = _curtainEvent.watch((eventProps) => {
			const { id, ...rest } = eventProps;

			setCurtainProps(rest);
			setCurtainId((prevId) => (prevId === id ? null : id));
		});

		return unwatch;
	}, []);

	return (
		<CurtainOverlay
			dataTest="new-curtain-panel"
			open={curtainId !== null}
			onClose={onClose}
			{...curtainProps}
		/>
	);
}

export { CurtainShift, CurtainOverlay, CurtainInLayout, enqueueCurtain };
