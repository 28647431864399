import { useStore } from 'effector-react';
import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { CurtainOverlay } from 'components/Curtain';
import FilterLine from 'components/FilterLine';
import LoadMoreButton from 'components/LoadMoreButton';
import { piiFilterWithGroupsStore } from 'components/PiiGlobalFilterV2/model';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { EventsStatisticJson } from 'models/eventsV2/dto';
import {
	EventParams,
	eventsModel,
	EventFromParam,
	EventTypeTypeParam,
} from 'models/eventsV2/model';
import { getReleaseFx } from 'models/release/effects';
import { dayjs } from 'services/dayjs';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { getEventsStatistic } from '../../models/eventsV2/api.ts';
import { ApiParams } from '../../services/api.ts';
import { BreakdownByIncident } from './BreakdownByIncident';
import EventDrawer from './EventDrawer';
import { EventsBySensitivity } from './EventsBySensitivity';
import { EventsTable } from './EventsTable';
import styles from './index.module.css';

const pageConfig = {
	id: {
		type: 'number',
		persistence: 'none',
	},
	type: {
		type: 'string',
		persistence: 'session',
	},
	from: {
		type: 'string',
		persistence: 'session',
	},
	'cluster-ids': {
		type: 'numberArray',
		persistence: 'session',
	},
	namespaces: {
		type: 'stringArray',
		persistence: 'session',
	},
	regions: {
		type: 'stringArray',
		persistence: 'session',
	},
	severity: {
		type: 'string',
		persistence: 'session',
	},
	policy_tags: {
		type: 'stringArray',
		persistence: 'session',
	},
	resourceTypes: {
		type: 'stringArray',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

type Filter = {
	type: EventParams['type'];
	from: EventFromParam;
	'data-types': number[]; // TODO
	'cluster-ids': EventParams['cluster_ids'];
	namespaces: EventParams['namespaces'];
	regions: EventParams['regions'];
	severity: EventParams['severity'];
	resourceTypes: EventParams['resource_types'][];
};

const initialFilter: Filter = {
	type: '',
	from: '90',
	'data-types': [] as number[], // TODO
	'cluster-ids': [] as number[], // TODO
	namespaces: [],
	regions: [],
	severity: '',
	resourceTypes: [],
};

const initialStatistic: EventsStatisticJson = {
	counters_filtered: { total: 0, critical: 0, high: 0, medium: 0, low: 0, info: 0 },
	breakdown_by_event: [],
};

let prevNotEmptyFiltersString = '';

function Events() {
	const state = useStore(eventsModel.store);
	const [eventsStatistic, setEventsStatistic] = useState<EventsStatisticJson>(initialStatistic);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'activityLog');

	useEffect(() => {
		setPageParams(pageParams);
	}, [piiFilterWithGroups]);

	// filter for eventStatistic
	useEffect(() => {
		const notEmptyFilters: ApiParams = {};
		if (pageParams.type) notEmptyFilters.type = pageParams.type;
		if (pageParams.from)
			notEmptyFilters.from = dayjs()
				.subtract(Number(pageParams.from || initialFilter.from), 'day')
				.startOf('day')
				.valueOf();
		if (pageParams.resourceTypes.length)
			notEmptyFilters.resource_types = pageParams.resourceTypes.join(',');
		if (pageParams['cluster-ids'].length)
			notEmptyFilters.cluster_ids = pageParams['cluster-ids'].join(',');
		if (pageParams.severity) notEmptyFilters.severity = pageParams.severity;
		if (pageParams.policy_tags.length)
			notEmptyFilters.policy_tags = pageParams.policy_tags.join(',');

		// because pageParams.id changes every single click to events table row
		// TODO: why Object.property deps doesn't work? There is no pageParams.id in deps
		const currentNotEmptyFilterString = JSON.stringify(notEmptyFilters);
		if (prevNotEmptyFiltersString === currentNotEmptyFilterString) {
			return;
		}
		prevNotEmptyFiltersString = currentNotEmptyFilterString;

		getEventsStatistic(notEmptyFilters).then((statistic) => {
			setEventsStatistic({
				counters_filtered: statistic.counters_filtered,
				breakdown_by_event: statistic.breakdown_by_event.sort((a, b) => b.count - a.count),
			});
		});
	}, [
		pageParams.type,
		pageParams.from,
		pageParams.resourceTypes,
		pageParams['cluster-ids'],
		pageParams.severity,
		pageParams.policy_tags,
	]);

	useEffect(() => {
		const {
			type,
			from,
			'cluster-ids': clusterIds,
			namespaces,
			resourceTypes,
			regions,
			severity,
			policy_tags,
		} = pageParams;

		const paramsForFx: Partial<EventParams> = {
			type: type as EventTypeTypeParam,
			from: dayjs()
				.subtract(Number(from || initialFilter.from), 'day')
				.startOf('day')
				.valueOf(),
			cluster_ids: clusterIds,
			namespaces,
			resource_types: resourceTypes,
			severity,
			regions,
			policy_tags,
		};

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { sort, ...prevParams } = state.params;
		if (isEqual(paramsForFx, prevParams)) return;

		eventsModel.fetchFx(paramsForFx);
		getReleaseFx();
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			eventsModel.resetFx();
		};
	}, []);

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	// should exist but there is no in new design
	//
	// function onResetFilters() {
	// 	setPageParams({ ...pageParams, ...initialFilter });
	// 	clearPiiFilter();
	// }

	const { status, hasMoreData, params } = state;
	const hasFilter =
		(params['data-type-ids'].length > 0 && params['data-type-ids'][0] !== 'nonempty') ||
		params.type.length > 0 ||
		pageParams.from !== initialFilter.from ||
		params.cluster_ids.length > 0 ||
		params.namespaces.length > 0 ||
		params.resource_types.length > 0 ||
		params.policy_tags.length > 0;
	const [isCurtainOpen, setCurtainOpenState] = useState(false);

	function onSelectEvent(eventId: number) {
		setPageParams({
			...pageParams,
			id: eventId,
		});
	}

	const rightCurtainPart = <EventDrawer id={pageParams.id} openCurtain={setCurtainOpenState} />;

	return (
		<>
			<Header>
				<Header.Breadcrumbs finalStep="Activity log" />
				<Header.Title>Activity log</Header.Title>
			</Header>

			<FilterLine
				config={[
					'eventStart',
					'severity',
					'resourceTypes',
					'clusters',
					'namespaces',
					'eventTypeV2',
					'tags',
				]}
				values={{
					eventStart: (pageParams.from || initialFilter.from) as Filter['from'],
					severity: pageParams['severity'],
					resourceTypes: pageParams['resourceTypes'],
					clusters: pageParams['cluster-ids'],
					namespaces: pageParams.namespaces,
					eventTypeV2: pageParams.type,
					tags: pageParams['policy_tags'],
				}}
				onChange={(newValues) => {
					onFilterLineUpdate({
						from: newValues.eventStart,
						severity: newValues.severity,
						resourceTypes: newValues.resourceTypes,
						'cluster-ids': newValues.clusters,
						namespaces: newValues.namespaces,
						type: newValues.eventTypeV2,
						policy_tags: newValues.tags,
					});
				}}
			/>

			<div className={styles.countersBlock}>
				<EventsBySensitivity data={eventsStatistic.counters_filtered} />
				<BreakdownByIncident data={eventsStatistic.breakdown_by_event} />
			</div>

			{/* {state.data.length === 0 && hasFilter && <ResetFilters onReset={onResetFilters} />} */}
			{!(state.data.length === 0 && hasFilter) && (
				<EventsTable
					events={state.data || []}
					isLoading={status === 'loading'}
					onSelect={onSelectEvent}
					loadMoreButton={
						<LoadMoreButton
							show={hasMoreData}
							loading={status === 'loadingMore'}
							request={eventsModel.fetchMoreFx}
							className={styles.loadMore}
						/>
					}
				/>
			)}

			<CurtainOverlay
				open={isCurtainOpen}
				onClose={() => {
					onSelectEvent(0);
					setCurtainOpenState(false);
				}}
				classes={{
					rightPartOpen: styles.curtain,
				}}
				rightPart={rightCurtainPart}
			/>
		</>
	);
}

export default Events;
